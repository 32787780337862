import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, Clock, ExternalLink } from 'react-feather'
import { Tabs, Tab } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import WorldMap from "../../components/worldmap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class Canada extends React.Component {
  constructor(props){
      super(props);

      this.state = {
          showEcosystemsLink: false,
          showNextLink: false,
          showPrevLink: false
      }

      this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
      // Esc key press
      if(event.keyCode === 27) {
          this.setState({
              showEcosystemsLink: true
          });

          setTimeout(() => {
              let linkRef = document.getElementById("esc-action");
              linkRef.click();
          }, 50)
      }

      // Left Arrow key press
      if(event.keyCode === 37) {
          this.setState({
              showPrevLink: true
          });

          setTimeout(() => {
              let prevLinkRef = document.getElementById("prev-action");
              prevLinkRef.click();
          }, 50)
      }

      // Right Arrow key press
      if(event.keyCode === 39) {
          this.setState({
              showNextLink: true
          });

          setTimeout(() => {
              let nextLinkRef = document.getElementById("next-action");
              nextLinkRef.click();
          }, 50)
      }
  }
  componentDidMount(){
      document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
      return (
                <Layout>
                  {
                      this.state.showEcosystemsLink &&
                      <AniLink id="esc-action" fade to="/startup-visas" duration={0.4}></AniLink >
                  }
                  {
                      this.state.showPrevLink &&
                      <AniLink id="prev-action" fade to="/startup-visas" duration={0.4}></AniLink >
                  }
                  {
                      this.state.showNextLink &&
                      <AniLink id="next-action" fade to="/startup-visas/estonia" duration={0.4}></AniLink >
                  }
                  <SEO title="Canada's Startup Visa" />
                  <div id="top-part">
                    <div id="back">
                      <AniLink fade to="/startup-visas" duration={0.4}>
                        <em>{'<-'}</em> All Startup Visas
                      </AniLink>
                    </div>
                  <div id="contains-map">
                    <div id="canada_map_container">
                    <WorldMap />
                    </div>
                    </div>
                    <div id="left-col">
                      <div id="left-col-content">
                        <div className="page-icon-container" style={{fontSize:'48px'}}>
                        <span role="img" aria-label="Canada">🇨🇦</span>
                        </div>
                        <h1 id="country-header">Canada's Startup Visa</h1>

                      </div>
                    </div>
                    <div id="right-col">

                    {/* <AniLink fade to="/interviews/canada" className="colled">
                        <div className="long-container">
                          
                          <div className="item-header">
                          <Coffee className="slack-icon"/> 
                          Interview with Manager
                            <div className="item-arrow">
                              →
                            </div>
                          </div>
                          <div className="item-description">
                          </div>

                        </div>
                      </AniLink> */}
                    <OutboundLink className="colled" href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/start-visa.html" target="_blank" rel="noopener noreferrer">
                        <div className="external-container">
                          <div className="item-header">
                          <div className="long-icon-container">
                          < Globe className="slack-icon"/> 
                          </div>
                          Official Website
                            <div className="item-arrow">
                            <ExternalLink className="slack-icon"/> 

                            </div>
                          </div>
                          <div className="item-description">
                          </div>

                        </div>
                      </OutboundLink>
                      <OutboundLink href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/applying-permanent-residence-start-visa.html" target="_blank" rel="noopener noreferrer">
                        <div className="external-container">
                          
                          <div className="item-header">
                          <div className="long-icon-container">
                          <Globe className="slack-icon"/> 
                          </div>
                          Apply Now
                            <div className="item-arrow">
                            <ExternalLink className="slack-icon"/> 
                            </div>
                          </div>
                          <div className="item-description">
                          </div>

                        </div>
                      </OutboundLink>
                  
                    </div>
                  </div>
                  <div className="content-container">

                  <Tabs defaultActiveKey="visa-description" id="country-tabs">
                  <Tab eventKey="visa-description" title="Visa Information" id="visa-information-tab">
                  <p>Canada’s Start-up Visa Program targets immigrant entrepreneurs with the skills and potential to build businesses in Canada that are innovative, can create jobs for Canadians, and can compete on a global scale. If you have an innovative business idea and can get support for your idea from one of the designated organizations, you may be able to immigrate to Canada.</p>
                  <p>Your business idea or venture must get the support of at least one designated Venture capital fund, an Angel investor group or a business incubator. These organizations are approved to invest in or support possible start-ups through the Start-up Visa Program.</p>
                    <table className="country-table">
                      <tr>
                        <td className="table-label">Degree Required</td>
                        <td className="table-value">Not Required</td>
                      </tr>
                      <tr>
                        <td className="table-label">Visa Duration</td>
                        <td className="table-value">Permanent</td>
                      </tr>
                        <tr>
                        <td className="table-label">Bootstrappers</td>
                        <td className="table-value">Yes</td>
                      </tr>
                        <tr>
                        <td className="table-label">Visa Fees</td>
                        <td className="table-value">$1,180</td>
                      </tr>
                        <tr>
                        <td className="table-label">Personal Funds</td>
                        <td className="table-value">$9,700</td>
                      </tr>
                        <tr>
                        <td className="table-label">Minimum Investment</td>
                        <td className="table-value">$0</td>
                      </tr>
                        <tr>
                        <td className="table-label">Processing Time</td>
                        <td className="table-value">365 days</td>
                      </tr>
                    </table>
                  </Tab>
                  <Tab eventKey="reviews" title="Founder Reviews" id="visa-information-tab">
                    <div className="jobs-container">
                        <div className="review-container">
                            <div className="job-description">
                              <p>I’ve managed to successfully immigrate to Canada, incorporate, and start my business. It was a really smooth process that didn’t take much effort besides getting the paperwork in order. Really recommended, it’s really a great place to be and build the future.</p>
                            </div>
                            <div className="interview-details">
                              <div className="left-details">
                              <div className="job-company">
                              Ziyad Basheer
                              </div>
                              <div className="job-tags">
                                <div className="job-tag">
                                  <div className="job-tag-text">
                                    Founder
                                  </div>
                                </div>
                                </div>
                              </div>
                              <div className="right-details">
                                <div className="job-item">
                                  <div>
                                    <Clock className="job-icon"/>
                                  </div>
                                  Processed in 15 days
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                  </Tab>
                </Tabs>
                  </div>
                </Layout>
              
              )
        }
      }
